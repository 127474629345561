<template>
  <b-container fluid>
    <!-- WELCOME -->
    <BaseWelcome
      :name="user.name"
      :greeting-message="$t('welcome')"
    />

    <BaseGuideline
      :title="$t('guideline_title')"
      :message="$t('guideline_message')"
      icon="DownloadIcon"
    />

    <!-- TASKS / DELIVERIES TODAY -->
    <BaseTask
      :counter="taskTodayCount"
      :title="$t('tasktoday_title')"
      :description="$t('tasktoday_description')"
    />

    <!-- PERFORMANCE RATING -->
    <BasePerformance
      :value="value"
      :title="$t('performance_title')"
      :message="$t('performance_message')"
    />

    <!-- MILESTONES -->
    <BaseMilestone
      :milestones="milestones"
      :title="$t('milestones_title')"
      :description="$t('milestones_description')"
    />
  </b-container>
</template>

<script>
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import {
  BaseWelcome, BaseTask, BasePerformance, BaseMilestone, BaseGuideline,
} from '@/components'

export default {
  name: 'MyDashboard',
  components: {
    BaseWelcome,
    BaseTask,
    BasePerformance,
    BaseMilestone,
    BaseGuideline,
  },
  data() {
    return {
      value: 0,
      milestones: [],
      taskTodayCount: 0,
      deliveries: 0,
      user: { name: 'Name' },
    }
  },
  created() {
    const self = this
    const headers = { Accept: 'application/json' }
    this.user = getUserData()
    this.tasksTodayCount()
    axios.get(`${this.$appURL}milestone`, { headers })
      .catch(() => {})
      .then(response => {
        self.milestones = response.data.data
        self.deliveries = response.data.count
        if (self.deliveries <= 10) {
          self.value = 1
        } else if (self.deliveries >= 11 || self.deliveries <= 20) {
          self.value = 2
        } else if (self.deliveries >= 21 || self.deliveries <= 30) {
          self.value = 3
        } else if (self.deliveries >= 31 || self.deliveries <= 50) {
          self.value = 4
        } else {
          self.value = 5
        }
      })
  },
  methods: {
    async tasksTodayCount() {
      const response = await axios.get('smarttruck-dashboard')
      if (response.status === 200) {
        this.taskTodayCount = response.data.tasksToday
      }
    },
  },
}
</script>
